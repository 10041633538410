const SYSTEM_CONSTANTS = {
    IDENTITY: {
        HOST: 'https://uat.jasmine.hicas.vn',
        LOGIN: 'connect/token',
        FORGOT: 'users',
        PROFILE: 'users/1?populate=role',
        ROLE: 'users-permissions/roles',
        CAPTCHA: 'clients/captcha'
    },
    API_URL: "https://uat.jasmine.hicas.vn",
    LICENSE_MANAGER_API: "https://sit.stratusvue.anybim.vn"
};

export const REACTDnD = {
  ItemTypes: {
    JOB_ITEM: "jobItem",
  },
};
export default SYSTEM_CONSTANTS;
