import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Typography,
  Upload,
  message,
  Image,
} from 'antd';
import { UploadProps } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { VehicleSelect } from 'components/Selects/VehicleSelect';
import { useSelectorRoot } from 'store/store';
import { IIssue } from 'api/types/issue';
import Utils from 'common/Utils';
import { useEffect } from 'react';
// import moment from "moment";
// import { JobDateEnum } from "common/define-types";
import SYSTEM_CONSTANTS from 'common/constants';

interface IProps {
  form: FormInstance<any>;
  setPathFile?: (value: string | null) => void;
  detailIssue?: IIssue;
}
const { Dragger } = Upload;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
export const VehicleBreakForm = ({
  form,
  setPathFile,
  detailIssue,
}: IProps) => {
  const AMOUNT_MONEY = {
    MAX: 8000000,
    MIN: 0,
  };
  const props: UploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 1,
    accept: 'image/*',
    action: `${SYSTEM_CONSTANTS.API_URL}/Issue/issues/uploadImage`,
    headers: {
      Authorization: Utils.token ? `Bearer ${Utils.token}` : '',
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setPathFile && setPathFile(info.file.response);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setPathFile && setPathFile(null);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const selectGroupMotorbikes = useSelectorRoot(
    (state) => state.group.groupMotorbikes
  );
  // const selectGroupIssues = useSelectorRoot((state) => state.group.groupIssue);
  // const selectJobDateSelected = useSelectorRoot(
  //   (state) => state.group.jobDateSelected
  // );
  // const selectGroupSelected = useSelectorRoot(
  //   (state) => state.group.groupSelected
  // );
  // const issues = selectGroupIssues.filter((issue) => {
  //   const issueDate = moment.utc(issue.createdTime).local();
  //   const startDate = moment(selectGroupSelected?.startDate);
  //   if (selectJobDateSelected === JobDateEnum.ALL) return true;
  //   else {
  //     return issueDate.diff(startDate, 'days') + 1 === selectJobDateSelected;
  //   }
  // });

  useEffect(() => {
    if (!!detailIssue) {
      form.setFields(Utils.objectToAntFormData(detailIssue));
    }
  }, [form, detailIssue]);

  return (
    <Form form={form} labelCol={{ span: 24 }} className='noMargin_form-item'>
      <Form.Item
        label={'Biển số xe'}
        name={'motobikeNumber'}
        rules={[
          {
            required: true,
            message: 'Không được bỏ trống',
          },
        ]}
      >
        <VehicleSelect
          onChange={(value) => form.setFieldValue('motobikeNumber', value)}
          // remove filter data
          motorBikesOptions={selectGroupMotorbikes}
          readOnly={!!detailIssue}
        />
      </Form.Item>
      <Form.Item
        label={'Số tiền sửa xe'}
        name={'amount_Money'}
        rules={[
          {
            required: true,
            message: 'Không được bỏ trống',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          stringMode={true}
          readOnly={!!detailIssue}
          controls={false}
          max={AMOUNT_MONEY.MAX}
          min={AMOUNT_MONEY.MIN}
          formatter={(value) =>
            `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
          }
          parser={(value) => parseInt(value ? value?.replaceAll('.', '') : '0')}
        />
      </Form.Item>
      
      <Form.Item label={'Ghi chú'} name={'note'}>
        <Input.TextArea readOnly={!!detailIssue} />
      </Form.Item>
      {!!detailIssue ? (
        detailIssue.pathFile ? (
          <>
            <Typography.Text strong style={{ paddingBlock: 16 }}>
              Ảnh:
            </Typography.Text>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={`${SYSTEM_CONSTANTS.API_URL}${detailIssue.pathFile}`}
                width={'100%'}
              />
            </div>
          </>
        ) : (
          <></>
        )
      ) : (
        <Form.Item
          label={'Thêm ảnh'}
          valuePropName='fileList'
          name={'File'}
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Không được bỏ trống',
            },
          ]}
        >
          <Dragger {...props}>
            <FileImageOutlined />
            <p>Drag and drop here</p>
          </Dragger>
        </Form.Item>
      )}
    </Form>
  );
};
