import {
  Button,
  DatePicker,
  Form,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { exportExcelQuanLyTickets, exportQuanLyTickets } from "api/ticket.api";
import { IExportOpTicket, TourStatus } from "common/define-types";
import Utils from "common/Utils";
import { AgentSelect } from "components/Selects/AgentSelect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import styles from "../TicketOperator.module.css";

dayjs.extend(utc);

interface IProps {
  open: boolean;
  handleClose?: () => void;
  handleOk?: () => void;
}

// Create Modal for Export QuanLyTickets
enum OptionValue {
  GroupByAgent = 2,
  OrderByCreatedDate = 1,
  OrderByStartDate = 0,
}

enum DownloadOption {
  PDF = "PDF",
  EXCEL = "EXCEL",
}

const { RangePicker } = DatePicker;

const PrintTicketModal = ({ open, handleClose, handleOk }: IProps) => {
  const [paramsForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState<string>(DownloadOption.PDF);

  const handleSubmit = async () => {
    try {
      const paramsValue = await paramsForm.validateFields();

      let params: IExportOpTicket = {
        startDate: paramsValue?.rangeDate[0]?.startOf("day").toISOString(),
        endDate: paramsValue?.rangeDate[1]?.endOf("day").toISOString(),
        AgentId: paramsValue?.AgentId,
        TourStatus: paramsValue?.TourStatus,
        isGroupByAgent: paramsValue.OptionValue === OptionValue.GroupByAgent,
        sortBy:
          paramsValue.OptionValue === OptionValue.OrderByCreatedDate ||
          paramsValue.OptionValue === OptionValue.OrderByStartDate
            ? paramsValue.OptionValue
            : -1,
      };

      setLoading(true);

      const exportFunc =
        download === DownloadOption.PDF
          ? exportQuanLyTickets
          : exportExcelQuanLyTickets;

      exportFunc(params).subscribe(
        (res: Blob) => {
          if (res) {
            console.log(res)
            const url = window.URL.createObjectURL(res);
            window.open(url, "_blank");
          }
        },
        (err) => {
          console.error(err);
          notification.error({ message: "Có lỗi xảy ra" });
          setLoading(false);
        },
        () => setLoading(false)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadChange = (e: any) => {
    setDownload(e.target.value);
  };

  return (
    <Modal
      open={open}
      onCancel={loading ? undefined : handleClose}
      onOk={handleOk}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Typography.Title level={4} style={{ marginTop: 0, textAlign: "center" }}>
          Tải xuống danh sách vé
        </Typography.Title>
        <Form
          form={paramsForm}
          labelCol={{ span: 24 }}
          initialValues={{
            rangeDate: [
              dayjs.utc().startOf("day"),
              dayjs.utc().add(5, "days").endOf("day"),
            ],
            OptionValue: OptionValue.GroupByAgent,
          }}
        >
          <Form.Item name="rangeDate" label="Vé xuất phát từ ngày">
            <RangePicker
              allowClear={false}
              placeholder={["Từ ngày", "Đến ngày"]}
              format={Utils.dateFormat}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="DownloadValue">
            <Radio.Group onChange={handleDownloadChange} value={download}>
              <Space direction="horizontal">
                <Radio value={DownloadOption.EXCEL}>EXCEL</Radio>
                <Radio value={DownloadOption.PDF}>PDF</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {download !== DownloadOption.EXCEL && (
            <>
              <Form.Item label="Trạng thái vé" name="TourStatus">
                <Select
                  options={[
                    { label: "Chưa đến", value: TourStatus.Init },
                    { label: "Xác nhận nhà cung cấp", value: TourStatus.ConfirmByNCC },
                    { label: "Đã đến", value: TourStatus.CheckIn },
                    { label: "Đang đi", value: TourStatus.Running },
                    { label: "Kết thúc", value: TourStatus.EndTour },
                  ]}
                  allowClear
                  placeholder="-- Trạng thái vé --"
                />
              </Form.Item>
              <Form.Item label="Chọn đại lý" name="AgentId">
                <AgentSelect />
              </Form.Item>
            </>
          )}
          {download !== DownloadOption.EXCEL && (
            <Form.Item name="OptionValue">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={OptionValue.GroupByAgent}>Nhóm theo đại lý</Radio>
                  <Radio value={OptionValue.OrderByCreatedDate}>
                    Sắp xếp theo ngày giờ bán vé
                  </Radio>
                  <Radio value={OptionValue.OrderByStartDate}>
                    Sắp xếp theo ngày giờ xuất phát
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" onClick={handleSubmit}>
            Tải xuống
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default PrintTicketModal;
