import {
  Typography,
  Checkbox,
  Button,
  Skeleton,
  notification,
  Popconfirm,
} from "antd";
import styles from "../Operation.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { Customer } from "./Customer";
import { CheckOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  checkJobTickets,
  createNewIssue,
  saveIssue,
  saveJobTickets,
  uncheckJobTickets,
} from "store/slice/GroupSlice";
import Utils from "common/Utils";
import {
  GroupStatusEnum,
  GroupTicketType,
  IGroupTicket,
  IssueStatus,
  IssueType,
  JobTicketStatusEnum,
  JobTicketType,
} from "common/define-types";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { GasMoney } from "./GasMoney";
import { useEffect, useState } from "react";

export const CustomerList = () => {
  const [jobTotalGasMoney, setJobTotalGasMoney] = useState(0);
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const selectJobTourSelected = useSelectorRoot(
    (state) => state.group.jobTourSelected
  );
  const selectJobTickets = useSelectorRoot((state) => state.group.jobTickets);
  const selectJobTicketSubmitting = useSelectorRoot(
    (state) => state.group.isJobTicketSubmitting
  );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectIsJobLoading = useSelectorRoot(
    (state) => state.group.isJobLoading
  );
  const selectIsJobTicketsLoading = useSelectorRoot(
    (state) => state.group.isJobTicketsLoading
  );
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );
  const hasJobTicketDriver =
    selectJobTickets.filter((jTicket) => jTicket.type === JobTicketType.Driver)
      .length > 0;
  const hasJobTicketMotor =
    selectJobTickets.filter((jTicket) => jTicket.type === JobTicketType.Motor)
      .length > 0;
  const isMissingTourLead =
    selectJobTourSelected?.day === "4" &&
    Utils.checkGroupEndDateIsBeforeCountDay(selectGroupSelected);

  const dispatch = useDispatchRoot();

  useEffect(() => {
    if (selectJobTourSelected) {
      setJobTotalGasMoney(selectJobTourSelected.sumarizedMoney);
    }
  }, [selectJobTourSelected]);

  const handleCheckJobTickets = (
    e: CheckboxChangeEvent,
    jobTicketType: JobTicketType
  ) => {
    if (selectJobTourSelected) {
      const jobTickets = [...selectJobTickets].filter((jTicket) => {
        const foundGroupTicket = selectGroupTickets.find(
          (gTicket) => gTicket.id === jTicket.ticketId
        );
        if (jobTicketType === JobTicketType.Tickets) {
          return (
            jTicket.type === JobTicketType.Tickets &&
            jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
            foundGroupTicket?.type !== GroupTicketType.DUMMY
          );
        } else {
          return (
            jTicket.type === jobTicketType &&
            jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
          );
        }
      });
      if (e.target.checked) {
        dispatch(checkJobTickets(jobTickets));
      } else {
        dispatch(uncheckJobTickets(jobTickets));
      }
    }
  };
  const handleSaveJobTickets = () => {
    if (selectJobTourSelected) {
      if (selectJobTourSelected.jobItem?.type === JobTicketType.Oil) {
        if (
          selectJobTickets
            .filter(
              (jTicket) =>
                jTicket.type === JobTicketType.Oil &&
                jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
            )
            .every((jTicket) => !jTicket.isCheck) &&
          jobTotalGasMoney > 0
        ) {
          notification.info({
            message: "Để cập nhật tiền đổ xăng, cần check trước ít nhất 1 vé !",
          });
          return;
        }
        if (selectJobTourSelected.datA_TOUR_Issues.length > 0) {
          dispatch(
            saveIssue({
              ...selectJobTourSelected.datA_TOUR_Issues[0],
              issueId: selectJobTourSelected.datA_TOUR_Issues[0].id,
              amount_Money: jobTotalGasMoney.toString(),
            })
          );
        } else {
          if (jobTotalGasMoney > 0) {
            dispatch(
              createNewIssue({
                leaderId: selectGroupSelected?.leaderId,
                groupId: selectGroupSelected?.id,
                type: IssueType.FUEL,
                jobTourId: selectJobTourSelected.id,
                amount_Money: jobTotalGasMoney.toString(),
                status: IssueStatus.Init,
              })
            );
          }
        }
      }
      dispatch(
        saveJobTickets(
          selectJobTickets.map((jTicket) => ({
            ...jTicket,
            timeCheckin: Utils.convertToVNTimeZone(new Date()),
          }))
        )
      );
    }
  };

  const getStatusOfJob = () => {
    if (selectJobTourSelected) {
      const tickets = selectJobTourSelected.jobTickets.filter(
        (ticket) => ticket.status !== JobTicketStatusEnum.BREAKUPTOUR
      );
      if (tickets.every((ticket) => ticket.status === JobTicketStatusEnum.INIT))
        return {
          color: "#1890FF",
          content: "Chưa pass",
        };
      if (tickets.some((ticket) => ticket.status === JobTicketStatusEnum.PASS))
        return {
          color: "#52C41A",
          content: "Đã pass",
        };
      if (
        tickets.some((ticket) => ticket.status === JobTicketStatusEnum.CONFIRM)
      )
        return {
          color: "#FBBC04",
          content: "Đã confirm",
        };
    }
    return {
      color: "#1890FF",
      content: "Chưa pass",
    };
  };
  return (
    <div className={styles.detailView}>
      <div
        className={`${styles.detailView_title} ${styles.spaceBetween}`}
        style={{ paddingRight: "14px" }}
      >
        <div>
          <Typography.Title level={4} style={{ marginBlock: 0 }}>
            Danh sách khách ở điểm:
          </Typography.Title>
          <Typography.Text style={{ color: "#1890FF" }}>
            {selectJobTourSelected?.name}
          </Typography.Text>
        </div>
        {selectIsJobLoading ||
        selectIsJobTicketsLoading ||
        isMissingTourLead ? (
          <></>
        ) : (
          <div className={styles.checkboxContainer}>
            {selectJobTickets.filter(
              (jTicket) => jTicket.type === JobTicketType.Tickets
            ).length > 0 && (
              <div className={styles.checkboxItem}>
                <Typography>Vé khách</Typography>
                <Checkbox
                  className="strongBorder"
                  checked={
                    selectJobTickets
                      .filter((jTicket) => {
                        const foundGroupTicket = selectGroupTickets.find(
                          (gTicket) => gTicket.id === jTicket.ticketId
                        );
                        if (
                          jTicket.type === JobTicketType.Tickets &&
                          jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
                          foundGroupTicket?.type !== GroupTicketType.DUMMY
                        ) {
                          return true;
                        }
                        return false;
                      })
                      .every((jTicket) => jTicket.isCheck) &&
                    selectJobTickets.filter((jTicket) => {
                      const foundGroupTicket = selectGroupTickets.find(
                        (gTicket) => gTicket.id === jTicket.ticketId
                      );
                      if (
                        jTicket.type === JobTicketType.Tickets &&
                        jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
                        foundGroupTicket?.type !== GroupTicketType.DUMMY
                      ) {
                        return true;
                      }
                      return false;
                    }).length > 0
                  }
                  onChange={(e: CheckboxChangeEvent) =>
                    handleCheckJobTickets(e, JobTicketType.Tickets)
                  }
                />
              </div>
            )}
            {selectJobTickets.filter(
              (jTicket) => jTicket.type === JobTicketType.Oil
            ).length > 0 && (
              <div className={styles.checkboxItem}>
                <Typography>Vé khách</Typography>
                <Checkbox
                  className="strongBorder"
                  checked={
                    selectJobTickets
                      .filter(
                        (jTicket) =>
                          jTicket.type === JobTicketType.Oil &&
                          jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
                      )
                      .every((jTicket) => jTicket.isCheck) &&
                    selectJobTickets.filter(
                      (jTicket) =>
                        jTicket.type === JobTicketType.Oil &&
                        jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
                    ).length > 0
                  }
                  onChange={(e: CheckboxChangeEvent) =>
                    handleCheckJobTickets(e, JobTicketType.Oil)
                  }
                />
              </div>
            )}
            {hasJobTicketDriver && (
              <div className={styles.checkboxItem}>
                <Typography>Vé NV</Typography>
                <Checkbox
                  className="strongBorder"
                  checked={
                    selectJobTickets
                      .filter(
                        (jTicket) =>
                          jTicket.type === JobTicketType.Driver &&
                          jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
                      )
                      .every((jTicket) => jTicket.isCheck) &&
                    selectJobTickets.filter(
                      (jTicket) =>
                        jTicket.type === JobTicketType.Driver &&
                        jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
                    ).length > 0
                  }
                  onChange={(e: CheckboxChangeEvent) =>
                    handleCheckJobTickets(e, JobTicketType.Driver)
                  }
                />
              </div>
            )}
            {hasJobTicketMotor && (
              <div className={styles.checkboxItem}>
                <Typography>Vé xe</Typography>
                <Checkbox
                  className="strongBorder"
                  checked={
                    selectJobTickets
                      .filter(
                        (jTicket) =>
                          jTicket.type === JobTicketType.Motor &&
                          jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
                      )
                      .every((jTicket) => jTicket.isCheck) &&
                    selectJobTickets.filter(
                      (jTicket) =>
                        jTicket.type === JobTicketType.Motor &&
                        jTicket.status !== JobTicketStatusEnum.BREAKUPTOUR
                    ).length > 0
                  }
                  onChange={(e: CheckboxChangeEvent) =>
                    handleCheckJobTickets(e, JobTicketType.Motor)
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
      {selectIsJobLoading || selectIsJobTicketsLoading ? (
        <></>
      ) : (
        <div
          style={{
            marginTop: 12,
            paddingInline: 12,
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {selectJobTickets.length > 0 && (
            <div>
              <Typography.Text
                style={{
                  color: getStatusOfJob().color,
                  fontSize: 16,
                  lineHeight: "12px",
                  marginRight: 8,
                }}
              >{`•`}</Typography.Text>
              <Typography.Text style={{ color: getStatusOfJob().color }}>
                {getStatusOfJob().content}
              </Typography.Text>
            </div>
          )}
          {selectJobTourSelected?.jobItem?.type === JobTicketType.Oil && (
            <GasMoney
              value={jobTotalGasMoney}
              onChange={(value) =>
                setJobTotalGasMoney(value ? parseInt(value) : 0)
              }
              disabled={getStatusOfJob().content === "Đã confirm"}
            />
          )}
        </div>
      )}
      <div className={styles.detailView_wrapper}>
        <div className={`${styles.customerScrollContainer} customScroll`}>
          {selectIsJobLoading || selectIsJobTicketsLoading ? (
            <>
              <Skeleton
                avatar={{ size: "small" }}
                paragraph={{ rows: 1 }}
                active
              />
              <Skeleton
                avatar={{ size: "small" }}
                paragraph={{ rows: 1 }}
                active
              />
              <Skeleton
                avatar={{ size: "small" }}
                paragraph={{ rows: 1 }}
                active
              />
            </>
          ) : selectJobTourSelected && selectJobTickets.length > 0 ? (
            selectJobTickets
              .filter(
                (jTicket) =>
                  jTicket.type === JobTicketType.Tickets ||
                  jTicket.type === JobTicketType.Oil
              )
              .map((ticket) => {
                const ticketInfor: IGroupTicket | null =
                  selectGroupTickets.filter(
                    (gTicket) => gTicket.id === ticket.ticketId
                  ).length > 0
                    ? selectGroupTickets.filter(
                        (gTicket) => gTicket.id === ticket.ticketId
                      )[0]
                    : selectGroupTickets.length > 0
                    ? selectGroupTickets[0]
                    : null;
                return {
                  ...ticket,
                  fullName: ticketInfor ? ticketInfor.fullName : "",
                };
              })
              .sort((a, b) => {
                if (a.status === JobTicketStatusEnum.BREAKUPTOUR) return 1;
                if (b.status === JobTicketStatusEnum.BREAKUPTOUR) return -1;
                if (a.isDriver === "YES" && b.isDriver === "YES")
                  return a.fullName.localeCompare(b.fullName);
                if (a.isDriver === "YES") return -1;
                if (b.isDriver === "YES") return 1;
                return a.fullName.localeCompare(b.fullName);
              })
              .map((ticket) => (
                <Customer
                  ticket={ticket}
                  key={ticket.id}
                  isCancelTour={ticket.status === 3}
                  isMissingTourLead={isMissingTourLead}
                  listHasJobTicketDriver={hasJobTicketDriver}
                  listHasJobTicketMotor={hasJobTicketMotor}
                />
              ))
          ) : (
            <EmptyHolder message="No ticket" />
          )}
        </div>
      </div>
      <div className={styles.detailView_footer}>
        {selectGroupSelected &&
          selectGroupSelected.status === GroupStatusEnum.RUNNING &&
          selectJobTourSelected &&
          Utils.isBusinessDateAvailable(
            selectGroupSelected,
            selectJobDateSelected
          ) && (
            <Popconfirm
              title="Cập nhật danh sách"
              description={"Bạn có xác nhận cập nhật"}
              onConfirm={handleSaveJobTickets}
            >
              <Button
                type="primary"
                icon={<CheckOutlined />}
                loading={selectJobTicketSubmitting}
                disabled={
                  (selectGroupSelected !== null &&
                    !Utils.isGroupTakingPlace(selectGroupSelected)) ||
                  (!!selectJobTourSelected &&
                    selectJobTourSelected.day === "4" &&
                    Utils.checkGroupEndDateIsBeforeCountDay(
                      selectGroupSelected
                    )) ||
                  selectJobTourSelected.jobTickets
                    .filter(
                      (ticket) =>
                        ticket.status !== JobTicketStatusEnum.BREAKUPTOUR
                    )
                    .some(
                      (ticket) => ticket.status === JobTicketStatusEnum.CONFIRM
                    )
                }
              >
                Cập nhật danh sách
              </Button>
            </Popconfirm>
          )}
      </div>
    </div>
  );
};
