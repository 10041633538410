import { Select } from "antd";
import { getAllMotorbikes } from "api/motorbike.api";
import Utils from "common/Utils";
import { IMotorbike, IParams, MotorTicketType } from "common/define-types";
import { useCallback, useEffect, useState } from "react";

interface IProps {
  value?: string | null;
  onChange?: (motorbike: IMotorbike, type: MotorTicketType) => void;
  onClear?: () => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  valuePropName?: "id";
  additionalOptions?: any[];
  testId: string;
  defaultOptions: IMotorbike[];
  params: {
    startDate: string | null;
    endDate: string | null;
    isAvailable: boolean;
    groupId?: string;
  };
}
interface IMotorbikeWithType extends IMotorbike {
  type: MotorTicketType;
}
const mapMotorWithType = (motors: IMotorbike[], type: MotorTicketType) => {
  return motors.map((motor) => ({
    ...motor,
    type,
  }));
};
export const GroupVehicleSelect = ({
  value,
  onChange = () => {},
  onClear,
  style,
  readOnly,
  valuePropName = "id",
  testId,
  defaultOptions = [],
  additionalOptions,
  params,
}: IProps) => {
  const [currentMotorbike, setCurrentMotorbike] = useState<
    IMotorbikeWithType | undefined
  >();
  const [motorbikes, setMotorbikes] = useState<IMotorbikeWithType[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IMotorbikeWithType[]>([]);

  const fetchMotorbikes = useCallback((args: IParams) => {
    setIsLoading(true);
    getAllMotorbikes({ ...args }).subscribe(
      (motorbikesRes) => {
        setMotorbikes(
          motorbikesRes.results
            .filter((motorbike: IMotorbike) => !!motorbike)
            .map((motorbike: IMotorbike) => ({
              ...motorbike,
              type: MotorTicketType.Jasmine,
            }))
        );
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (dropdownVisible) {
      fetchMotorbikes({
        ...params,
        pageSize: Utils.RANGE_PAGESIZE.MAX_INTERGER,
      });
    }
  }, [fetchMotorbikes, dropdownVisible, params]);

  useEffect(() => {
    if (!value) setCurrentMotorbike(undefined);
    if (
      value &&
      options.find((motorbike) => motorbike[valuePropName] === value)
    ) {
      setCurrentMotorbike(
        options.find((motorbike) => motorbike[valuePropName] === value)
      );
    }
  }, [value, options, valuePropName]);

  useEffect(() => {
    const newOptios = mapMotorWithType(
      dropdownVisible ? motorbikes : defaultOptions,
      MotorTicketType.Jasmine
    ).concat(additionalOptions || []);
    setOptions(newOptios);
  }, [dropdownVisible, motorbikes, defaultOptions, additionalOptions]);

  return (
    <Select
      data-test-id={testId}
      options={
        isLoading
          ? []
          : options?.map((motorbike) => ({
              label: motorbike.motorNumber,
              value: motorbike[valuePropName],
            }))
      }
      value={
        isLoading
          ? undefined
          : dropdownVisible &&
            options.find((motorbike) => motorbike[valuePropName] === value)
          ? value
          : currentMotorbike?.motorNumber
      }
      style={{
        minWidth: 100,
        ...style,
      }}
      disabled={readOnly}
      onChange={(value) => {
        const foundMotorbike = options.find(
          (motorbike) => motorbike[valuePropName] === value
        );
        foundMotorbike && onChange(foundMotorbike, foundMotorbike.type);
      }}
      loading={isLoading}
      showSearch
      allowClear={!!onClear}
      onClear={onClear}
      open={dropdownVisible}
      placement="bottomLeft"
      onDropdownVisibleChange={(open) => {
        if (open && isLoading) {
          return;
        } else {
          setDropdownVisible(open);
        }
      }}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      optionFilterProp="children"
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
    />
  );
};
