import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, switchMap, mergeMap, catchError } from 'rxjs';
import {
  GroupRunningDateEnum,
  GroupStatusEnum,
  IDriver,
  IDriversResult,
  IDriverTicket,
  IGetGroupsParams,
  IGroup,
  IGroupIssue,
  IGroupsResult,
  IGroupTicket,
  IIssuesResult,
  IJob,
  // IssueStatus,
  JobDateEnum,
  RootEpic,
} from 'common/define-types';
import { getAllGroups } from 'api/group.api';
import { AjaxError } from 'rxjs/ajax';
import { SegmentedValue } from 'antd/es/segmented';
import moment from 'moment';
import { IIssue } from 'api/types/issue';
import {
  confirmNightAuditGroup,
  getNightAuditGroupById,
} from 'api/nightaudit.api';
import Utils from 'common/Utils';

export interface NightAuditState {
  isLoading: boolean;
  isSubmitting: boolean;
  isIssueLoading: boolean;
  isJobLoading: boolean;
  groups: IGroup[] | [];
  groupsResult: IGroupsResult | null;
  groupSelected: IGroup | null;
  errMsg: string | null;
  jobTours: IJob[] | [];
  jobDateSelected: JobDateEnum | SegmentedValue;
  groupRunningDateSelected: GroupRunningDateEnum | SegmentedValue;
  groupSelectedTickets: IGroupTicket[];
  groupSelectedIssues: IGroupIssue[];
  groupSelectedDrivers: IDriver[];
  groupSelectedDriverTickets: IDriverTicket[];
  groupSelectedIsRunNight?: boolean;
  issuesResult: IIssuesResult | null;
  driversResult: IDriversResult | null;
  groupSelectedCount: any;
  filterValue: IGetGroupsParams;
  hasUnconfirmedGroup: boolean;
}

const initialState: NightAuditState = {
  isLoading: false,
  isSubmitting: false,
  isIssueLoading: false,
  isJobLoading: false,
  errMsg: null,
  groups: [],
  groupsResult: null,
  groupSelected: null,
  jobTours: [],
  jobDateSelected: 1,
  groupRunningDateSelected: GroupRunningDateEnum.ALL,
  groupSelectedTickets: [],
  groupSelectedDrivers: [],
  groupSelectedIssues: [],
  groupSelectedDriverTickets: [],
  issuesResult: null,
  driversResult: null,
  groupSelectedCount: {
    countTicket: 0,
    countDriver: 0,
    countMotor: 0,
  },
  filterValue: {
    isEndDay: false,
  },
  hasUnconfirmedGroup: false,
};

export const nightAuditSlice = createSlice({
  name: 'nightAudit',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchNightAuditGroupData: (
      state,
      action: PayloadAction<IGetGroupsParams | undefined>
    ) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.errMsg = null;
    },
    setGroups: (state, action: PayloadAction<IGroup[]>) => {
      state.groups = [...action.payload];
      state.isLoading = false;
      state.errMsg = null;
      state.hasUnconfirmedGroup = [...action.payload].some(
        (group) => !!group.isNotConfirmpPreviousDay
      );
    },
    setGroupsResult: (state, action: PayloadAction<IGroupsResult>) => {
      state.groupsResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    setDriversResult: (state, action: PayloadAction<IDriversResult>) => {
      state.driversResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    setJobTours: (state, action: PayloadAction<IJob[]>) => {
      state.jobTours = action.payload;
      state.isJobLoading = false;
    },
    setIssuesResult: (state, action: PayloadAction<IIssuesResult>) => {
      state.issuesResult = action.payload;
    },
    setIssues: (state, action: PayloadAction<IIssue[]>) => {
      state.groupSelectedIssues = action.payload;
      state.isJobLoading = false;
    },
    setDrivers: (state, action: PayloadAction<IDriver[]>) => {
      state.groupSelectedDrivers = action.payload;
      state.isJobLoading = false;
    },
    setDriverTickets: (state, action: PayloadAction<IDriverTicket[]>) => {
      state.groupSelectedDriverTickets = action.payload;
      state.isJobLoading = false;
    },
    setGroupTickets: (state, action: PayloadAction<IGroupTicket[]>) => {
      state.groupSelectedTickets = action.payload;
      state.isJobLoading = false;
    },
    setGroupIsRunNight: (state, action: PayloadAction<boolean>) => {
      state.groupSelectedIsRunNight = action.payload;
    },
    selectGroup: (state, action: PayloadAction<IGroup | null>) => {
      if (action.payload) {
        state.isJobLoading = true;
        state.isIssueLoading = true;
      }
      state.groupSelected = action.payload;
      state.isSubmitting = false;
      state.errMsg = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
      state.isJobLoading = false;
      state.isIssueLoading = false;
    },
    setJobDateSelected: (
      state,
      action: PayloadAction<JobDateEnum | SegmentedValue>
    ) => {
      state.jobDateSelected = action.payload;
    },
    setFilterValue: (state, action: PayloadAction<IGetGroupsParams>) => {
      state.filterValue = action.payload;
    },
    setGroupRunningDateSelected: (
      state,
      action: PayloadAction<GroupRunningDateEnum | SegmentedValue>
    ) => {
      state.groupRunningDateSelected = action.payload;
    },
    auditGroup: (state, action: PayloadAction<IConfirmAuditGroup>) => {
      state.isSubmitting = true;
    },
    setGroupSelectedCount: (state, action: PayloadAction<any>) => {
      state.groupSelectedCount = {
        ...state.groupSelectedCount,
        ...action.payload,
      };
    },
  },
});

const fetchGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchNightAuditGroupData.match),
    switchMap((re) => {
      return getAllGroups({
        ...state$.value.nightAuditor.filterValue,
        ...re.payload,
        isNotRunNight: true,
        status: GroupStatusEnum.RUNNING,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const groups = res.results ?? [];
            const oldSelectedGroup = state$.value.nightAuditor.groupSelected;
            // const filteredGroup = groups.filter((group: IGroup) => {
            //   const startDate = moment(group.startDate);
            //   const endDate = moment(group.startDate).add(
            //     group.dmuC_Tour.countDay - 1,
            //     "day"
            //   );
            //   const today = moment();
            //   if (today.isBetween(startDate, endDate)) {
            //     if (
            //       state$.value.nightAuditor.groupRunningDateSelected ===
            //       GroupRunningDateEnum.ALL
            //     )
            //       return true;
            //     else {
            //       return (
            //         today.diff(startDate, "days") ===
            //         state$.value.nightAuditor.groupRunningDateSelected
            //       );
            //     }
            //   }
            //   return false;
            // });
            // if (filteredGroup.length > 0) {
            //   return [
            //     nightAuditSlice.actions.setGroups(groups),
            //     nightAuditSlice.actions.setGroupsResult(res),
            //     nightAuditSlice.actions.selectGroup(filteredGroup[0]),
            //   ];
            // }
            if (
              oldSelectedGroup &&
              groups.some((group: IGroup) => group.id === oldSelectedGroup.id)
            ) {
              return [
                nightAuditSlice.actions.setGroups(groups),
                nightAuditSlice.actions.setGroupsResult(res),
                nightAuditSlice.actions.selectGroup(
                  groups.find(
                    (group: IGroup) => group.id === oldSelectedGroup.id
                  )
                ),
              ];
            }
            const selectGroupRunningDateSelected =
              state$.value.nightAuditor.groupRunningDateSelected;
            const filteredGroups = groups.filter((group: IGroup) => {
              if (
                selectGroupRunningDateSelected === GroupRunningDateEnum.ALL ||
                selectGroupRunningDateSelected === GroupRunningDateEnum.END_DAY
              )
                return true;
              else {
                return (
                  Utils.getDayFromBusinessDate(group) ===
                  selectGroupRunningDateSelected
                );
              }
            });

            return [
              nightAuditSlice.actions.setGroups(groups),
              nightAuditSlice.actions.setGroupsResult(res),
              nightAuditSlice.actions.selectGroup(filteredGroups[0] || null),
            ];
          } else {
            return [nightAuditSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          nightAuditSlice.actions.setErrMsg(
            'Có lỗi xảy ra khi lấy danh sách groups'
          ),
          nightAuditSlice.actions.selectGroup(null),
          nightAuditSlice.actions.setJobTours([]),
          nightAuditSlice.actions.setDrivers([]),
          nightAuditSlice.actions.setIssues([]),
        ])
      );
    })
  );
const fetchWhenSelectGroup$: RootEpic = (action$) =>
  action$.pipe(
    filter(selectGroup.match),
    switchMap((re) => {
      if (re.payload === null || re.payload === undefined || !re.payload.id) {
        return [
          nightAuditSlice.actions.setJobTours([]),
          nightAuditSlice.actions.setDrivers([]),
          nightAuditSlice.actions.setIssues([]),
        ];
      }
      return getNightAuditGroupById(re.payload.id).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const countDay = re.payload ? Utils.getGroupCountDay(re.payload) : 4;
            const jobs = res.jobs ?? [];
            const issues = res.issues ?? [];
            // res.issues.filter(
            //   (issue: IIssue) =>
            //     issue.status === IssueStatus.Approve ||
            //     issue.status === IssueStatus.ComfirmByAdmin
            // ) ?? [];
            const driverTickets: IDriverTicket[] = res.driver ?? [];
            const tickets = res.tickets ?? [];
            const jobDateSelect = moment(res.businessDate)
              .endOf('day')
              .isBefore(moment(res.startDate).startOf('day'))
              ? 1
              : moment(res.businessDate).diff(moment(res.startDate), 'days') +
                  2 <=
                countDay
              ? moment(res.businessDate).diff(res.startDate, 'days') + 2
              : countDay;
            return [
              nightAuditSlice.actions.setJobTours(jobs),
              nightAuditSlice.actions.setDriverTickets(
                driverTickets.map((dTicket) => ({
                  ...dTicket,
                  day: dTicket.day === 0 ? 1 : dTicket.day,
                }))
              ),
              nightAuditSlice.actions.setIssues(issues),
              nightAuditSlice.actions.setGroupTickets(tickets),
              nightAuditSlice.actions.setJobDateSelected(jobDateSelect),
              nightAuditSlice.actions.setGroupIsRunNight(res.isRunNight),
              nightAuditSlice.actions.setGroupSelectedCount({
                countTicket: res.countTicket,
                countDriver: res.countDriver,
                countMotor: res.countMotor,
              }),
            ];
          } else {
            return [nightAuditSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          return [
            nightAuditSlice.actions.setJobTours([]),
            nightAuditSlice.actions.setDrivers([]),
            nightAuditSlice.actions.setIssues([]),
            nightAuditSlice.actions.setErrMsg(
              'Có lỗi xảy ra khi lấy thông tin group'
            ),
          ];
        })
      );
    })
  );
const auditGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(auditGroup.match),
    switchMap((re) => {
      const tzOffset = new Date().getTimezoneOffset();
      const today = moment().subtract(tzOffset, 'minutes');
      return confirmNightAuditGroup(
        state$.value.nightAuditor.groupSelected?.id ?? '',
        re.payload,
        state$.value.nightAuditor.groupSelected?.isNotConfirmpPreviousDay
          ? today.clone().subtract(1, 'days').toISOString()
          : undefined
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [nightAuditSlice.actions.fetchNightAuditGroupData()];
          } else {
            return [
              nightAuditSlice.actions.setErrMsg(
                res?.response.error || 'Không thể kiểm toán group'
              ),
            ];
          }
        }),
        catchError((e: AjaxError) => {
          return [
            nightAuditSlice.actions.setErrMsg(
              e.response?.Message || 'Có lỗi xảy ra khi kiểm toán'
            ),
          ];
        })
      );
    })
  );
const fetchWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setFilterValue.match),
    switchMap((re) => {
      return [nightAuditSlice.actions.fetchNightAuditGroupData(re.payload)];
    })
  );
const handleChangeGroupRunningDate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setGroupRunningDateSelected.match),
    switchMap((re) => {
      const isEndDay = state$.value.nightAuditor.filterValue.isEndDay;
      const groupRunningDateSelected = re.payload;
      if (
        isEndDay === false &&
        groupRunningDateSelected === GroupRunningDateEnum.END_DAY
      ) {
        return [
          nightAuditSlice.actions.setFilterValue({
            ...state$.value.nightAuditor.filterValue,
            isEndDay: true,
          }),
        ];
      } else if (
        isEndDay === true &&
        groupRunningDateSelected !== GroupRunningDateEnum.END_DAY
      ) {
        return [
          nightAuditSlice.actions.setFilterValue({
            ...state$.value.nightAuditor.filterValue,
            isEndDay: false,
          }),
        ];
      }
      return [];
    })
  );

export const {
  selectGroup,
  fetchNightAuditGroupData,
  setGroups,
  setJobTours,
  setJobDateSelected,
  setGroupRunningDateSelected,
  setErrMsg,
  setDrivers,
  setIssues,
  setDriversResult,
  setGroupsResult,
  setIssuesResult,
  setDriverTickets,
  setGroupTickets,
  auditGroup,
  setGroupSelectedCount,
  setFilterValue,
} = nightAuditSlice.actions;

export const NightAuditEpics = [
  fetchGroup$,
  fetchWhenSelectGroup$,
  auditGroup$,
  fetchWhenFilter$,
  handleChangeGroupRunningDate$,
];

export const nightAuditReducer = nightAuditSlice.reducer;
